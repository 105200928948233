<template>
  <div>
    <div class="objectivebox is-clickable is-relative" @click="showObjective()" @contextmenu.prevent="$refs.objectiveMenu.open">
      <span class="pos-top-right is-clickable" @click.stop="$refs.objectiveMenu.open"><b-icon icon="dots-vertical"/></span>
      <div class="hero is-danger">
        <h4 class="title is-6">
          <b-icon v-if="objective.sport=='cycling'" size="is-small" icon="bike"/>
          <b-icon v-if="objective.sport=='running'" size="is-small" icon="run"/>
          {{ objective.name }}
          <span class="has-text-bold">{{ objective.priority }}</span>
        </h4>
      </div>
      <div class="mt-1">
        <p v-if="objective.estimated_duration || objective.distance">
          {{ objective.estimated_duration ? utimeToString(objective.estimated_duration) : ''}}
          {{ objective.distance ? showDistance(objective.distance) : '' }}
        </p>
        <b-taglist v-if="objective.skills || objective.profile_type">
          <b-tag type="is-primary" class="has-text-weight-bold" v-if="objective.profile_type">{{ $t('objective.'+objective.profile_type) }}</b-tag>
          <b-tag v-for="skill in objective.skills" :key="skill">{{ $t('objective.'+skill) }}</b-tag>
        </b-taglist>
      </div>
    </div>



    <vue-context ref="objectiveMenu">
      <li><a @click="edit()">{{ $t('general.edit') }}</a></li>
      <li><a @click="del()">{{ $t('general.delete') }}</a></li>
    </vue-context>
  </div>
</template>

<script>
import VueContext from 'vue-context';

export default {
  name: 'objectivebox',
  props: ['objective'],
  methods: {
    showObjective(){
    },

    del(){
      this.$store.dispatch('removeObjective', this.objective).then(() =>{
      }).catch(err => {
         console.error(err);
      });
    },

    edit(){
      this.$eventBus.$emit('show-modal-new-objective', { objective: this.objective });
    }
  },
  components: {
    VueContext
  }
}
</script>

<style lang="scss">
.objectivebox{
  border-bottom: solid;
}
</style>
