<template>
  <div>
    <div class="workoutbox is-relative is-clickable" @click="toWorkout()" @contextmenu.prevent="$refs.workoutMenu.open">
      <span class="pos-top-right z-1" @click.stop="$refs.workoutMenu.open"><b-icon icon="dots-vertical"/></span>
      <div class="hero is-info">
        <h4 class="title is-6">
          <b-icon v-if="workout.sport=='cycling'" size="is-small" icon="bike"/>
          <b-icon v-if="workout.sport=='running'" size="is-small" icon="run"/>
          {{ workout.name }}
        </h4>
      </div>
      <div class="workoutbox-subcontent mt-1">
        <div v-if="!boundActivity">
          <p> {{ utimeToString(workout.duration) }} </p>
          <p v-if="workout.data.load"> {{ $t('metrics.load') }} : {{ workout.data.load }}</p>
        </div>
        <div v-if="boundActivity">
          <p v-if="workout.duration">{{ $t('workout.planned')[0] }}:{{ utimeToString(workout.duration) }}</p>
          <p v-if="boundActivity.duration">{{ $t('workout.completed')[0] }}:{{ utimeToString(boundActivity.duration) }}</p>
          <p v-if="workout.data.distance">{{ $t('workout.planned')[0] }}:{{ showDistance(workout.data.distance) }}</p>
          <p v-if="boundActivity.data.distance">{{ $t('workout.completed')[0] }}:{{ showDistance(boundActivity.data.distance) }}</p>
          <p><span class="hr" v-if="boundActivity.data.avg_heart_rate"> {{ boundActivity.data.avg_heart_rate }}bpm </span> <span class="power" v-if="boundActivity.data.avg_power"> {{ boundActivity.data.avg_power }}w </span> </p>
          <p v-if="workout.data.load">{{ $t('workout.planned')[0] }} {{ $t('metrics.load') }}:{{ workout.data.load }}</p>
          <p v-if="boundActivity.data.load">{{ $t('workout.completed')[0] }} {{ $t('metrics.load') }}:{{ boundActivity.data.load }}</p>
        </div>
      </div>
      <WorkoutViewer class="workout-background" :workout="workout" :showAxis="false"/>
    </div>
    <b-button @click.native="validate()" v-if="showValidation && workout.date <= new Date() && !workout.bound_activity && (workout.date.isThisWeek() || workout.date.daysDiff(new Date()) < 3)"
      class="is-primary is-small w-100p">
        {{ $t('general.validate') }}
    </b-button>




    <vue-context ref="workoutMenu">
      <li v-if="workout.steps && workout.steps.length > 0"><a @click="exportWorkout()">{{ $t('general.export') }}</a></li>
      <template v-if="!workout.bound_activity">
        <li><a @click="edit()">{{ $t('general.edit') }}</a></li>
        <li v-for="(activity, i) in ugetActivitiesForDay(workout.date)" :key="i">
          <a v-if="!activity.bound_workout" @click="bindActivity(activity)">{{ $t('workout.bind_with') + activity.name }}</a>
        </li>
        <li v-if="workout.date <= new Date()"><a @click="completed()">{{ $t('workout.mark_as_completed') }}</a></li>
      </template>
      <template v-if="workout.bound_activity">
        <li><a @click="edit()">{{ $t('workout.edit_workout') }}</a></li>
        <li><a @click="editActivity()">{{ $t('activity.edit_activity') }}</a></li>
        <li><a @click="unbindActivity()">{{ $t('workout.unbind') }}</a></li>
      </template>
      <li><a @click="del()">{{ $t('general.delete') }}</a></li>
    </vue-context>
  </div>
</template>

<script>
import VueContext from 'vue-context';
import WorkoutViewer from '@/views/workout/WorkoutViewer.vue';

export default {
  name: 'workoutbox',
  props: {
    workout: { type: Object },
    showValidation: { type: Boolean, default: true },
  },
  computed: {
    boundActivity(){ return this.ugetActivityFromSerial(this.workout.bound_activity); }
  },
  methods: {
    toWorkout(){
      if(this.boundActivity)
        this.$router.push({ name: 'activity', params : { activityToView: this.boundActivity }, query: { serial: this.boundActivity.serial_number } });
      else
        this.$router.push({ name: 'workout', params : { workoutToView: this.workout }, query: { id: this.workout.id } });
    },

    exportWorkout(){
      this.$eventBus.$emit('show-modal-workout-exporter', { workout: this.workout });
    },

    async del(){
      try{
        if(this.boundActivity)
          await this.$store.dispatch('removeActivity', this.boundActivity);
        await this.$store.dispatch('removeWorkout', this.workout);
      }catch(err){
        console.error(err);
      }
    },

    edit(){
      this.$eventBus.$emit('show-modal-plan-workout', { date: this.workout.date, isEdit: true, workout: this.workout });
    },

    editActivity(){
      if(this.boundActivity)
        this.$eventBus.$emit('show-modal-edit-activity', { activity: this.boundActivity });
    },

    completed(){
      let activity = {
        name: this.workout.name,
        date: this.workout.date,
        timestamp: this.workout.date,
        duration: this.workout.duration,
        description: this.workout.description,
        type: this.workout.sport,
        data: { distance: 0, load: this.workout.load },
      };
      this.$store.dispatch('newActivity', activity);
    },

    bindActivity(activity){
      this.$store.dispatch('bindWorkoutActivity', { workout: this.workout, activity: activity }).then().catch(err => console.log(err));
    },

    unbindActivity(){
      if(this.workout.bound_activity)
        this.$store.dispatch('unbindWorkoutActivity', { workout: this.workout, activity: this.boundActivity }).then().catch(err => console.log(err));
    },

    validate(){
      this.$eventBus.$emit('show-modal-validate-workout', { workout: this.workout, type: 'WORKOUT' });
    }
  },
  components: {
    VueContext,
    WorkoutViewer,
  }
}
</script>

<style lang="scss">
.workoutbox{
  border-bottom: solid 1px;
}

.workoutbox-subcontent{
  position: relative;
  background-color: #FFFFFF55;
  z-index: 1;
}

.workout-background{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}
</style>
