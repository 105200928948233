<!-- Day Chart -->
<!-- Line chart but with data for each day instead of each seconds. -->
<!-- Line on mouse hover will jump between each day instead of being able to go on any pixel -->
<!-- This is a base chart for all days charts -->
<script>
import LineChart from './LineChart.vue';

export default{
  name: 'daychart',
  extends: LineChart,
  props: {
    startDate: Date,
  },
  data(){
    return{
      ONEDAY: 24 * 60 * 60 * 1000,
    };
  },
  methods: {
    //Need to redefine LineChart methods that will be called by childrens for proper inheritance
    initialize(){ LineChart.methods.initialize.call(this); },
    createChart(){
      LineChart.methods.createChart.call(this);

      let x = this.axisX(Date.now());
      if(this.indexOfPosition(x) >= this.data[0].length - 3) //Don't show future rect if there isn't at least 3 future day of data
        return;

      let width = this.width-x;
      this.futureOpacity = this.svg.append("rect")
        .attr('x', x)
        .attr('y', 0)
        .attr('width', width)
        .attr('height', this.height)
        .attr('fill', 'grey')
        .attr('opacity', 0.1);
    },

    getMousePos(event){
      var [mouse_x, mouse_y] = LineChart.methods.getMousePos.call(this, event); //get super mouse pos value
      //Pos will then be rounded to the nearest day value
      var selectedTime = this.axisX.invert(mouse_x);
      var day = Math.round(selectedTime.getTime()/this.ONEDAY);
      var roundedTime = new Date(day*this.ONEDAY);
      var new_mouse_x = this.axisX(roundedTime)
      return [new_mouse_x, mouse_y];
    },

    indexOfPosition(posX){
      return Math.round((this.axisX.invert(posX).getTime()-this.startDate.getTime())/this.ONEDAY);
    },
  },
}
</script>

<style lang="scss">
</style>
