<!-- Line Chart component -->
<!-- Base component for all line charts with the basics set -->
<template>
  <div class="linechart" ref="root"/>
</template>

<script>
import * as d3 from 'd3';

var currentId = 0; //Counter for each chart id

export default{
  name: 'linechart',
  props: {
    name: String,
    entries: Array,
    zoomListener: { //Callback that will be called on each zoom
      type: Function,
      default: ()=>{},
    },
    mouseMoveListener: { //Callback that will be called on mouse move
      type: Function,
      default: e=>{},
    },
    top: { type: Number,  default: 5 }, //Margins
    bottom: { type: Number, default: 5 },
    right: { type: Number, default: 30 },
    left: { type: Number, default: 30 },
    fillArea: { //True if fill area under line with color
      type: Boolean,
      default: false,
    },
    elementListener: { //If specified, all events will listen to this element instead of root
      type: String,
      default: '',
    },
    xAxis: { //Show xAxis if true
      type: Boolean,
      default: true,
    },
    xAxisOnBottom: { //Axis will be displayed on bottom if true, on top if false
      type: Boolean,
      default: true,
    },
    //Domains limits of entries to display (on y axis)
    domainStartAt: {
      type: Number,
      default: NaN,
    },
    domainEndAt: {
      type: Number,
      default: NaN,
    },
    //Times limits of entries to display
    timeStartAt: {
      type: Date,
      default: null,
    },
    timeEndAt: {
      type: Date,
      default: null,
    },
  },
  data(){
    return {
      width: 0,
      height: 0,
      id: currentId++, //New Id for each new chart. Used for events listener
      isMouseDown: false,
      zoomStart: 0,
      zoomed: false,
      data: [],
    };
  },
  computed: {
    domainWidth(){ return this.width - this.margin.left - this.margin.right; }, //width - margin
    domainHeight(){ return this.height - this.margin.top - this.margin.bottom; }, //height - margin
    margin(){
      var margin = {
        top: this.top,
        bottom: this.bottom,
        right: this.right,
        left: this.left+30, //30 more px on the left to display the hovered number
      };

      if(this.xAxis){ //Update margin top or bottom if axis is displayed
        if(this.xAxisOnBottom)
          margin.bottom += 20;
        else
          margin.top += 20;
      }
      return margin;
    },
  },
  methods: {
    build(){
      this.setSize();
      this.initialize();
      this.handleData();
      this.setAxis();
      this.createChart();
    },

    rebuild(){
      this.root.remove();
      this.build();
    },

    //Set width and height from root element
    setSize(){
      if(this.$refs.root){
        this.width = this.$refs.root.clientWidth;
        this.height = this.$refs.root.clientHeight;
      }
    },

    //Recreate chart (when resizing)
    recreateChart(){
      this.root.remove();
      this.setAxisRange(); //update axis range
      this.createChart();
    },


    //Called when initalizing data before creating chart
    initialize(){
      this.data = this.entries; //default data are entries
    },

    //Calc min max domain and time
    handleData(){
      this.minDomain = isNaN(this.domainStartAt) ? d3.min(this.data, data => d3.min(data, d => d.data)) : this.domainStartAt;
      this.maxDomain = isNaN(this.domainEndAt) ? d3.max(this.data, data => d3.max(data, d => d.data)) : this.domainEndAt;

      this.minTime = !this.timeStartAt ? d3.min(this.data, data => d3.min(data, d => d.date)) : this.TimeStartAt;
      this.maxTime = !this.timeEndAt ? d3.max(this.data, data => d3.max(data, d => d.date)) : this.TimeEndAt;
    },

    setAxisRange(){
      this.axisX.range([0, this.domainWidth]);
      this.axisY.range([this.domainHeight, 0]);
    },

    //Create d3 axis for chart
    setAxis(){
      this.axisX = d3.scaleUtc();//X axis is a time axis
      this.axisY = d3.scaleLinear();
      this.setAxisRange();
      this.axisX.domain(this.getMaxDomain()); //set domain
      this.axisY.domain([this.minDomain, this.maxDomain]);
      //Line function, take date and value from data
      if(this.fillArea)
        this.lineFunction = d3.area().x((d) => { return this.axisX(d.date); }).y1((d) => { return this.axisY(d.data); }).y0(this.domainHeight);
      else
        this.lineFunction = d3.line().x((d) => { return this.axisX(d.date); }).y((d) => { return this.axisY(d.data); });
    },

    //Create chart graphics
    createChart(){
      var self = this;


      //Create root svg
      this.root = d3.select(this.$refs.root).append("svg")
        .attr("class", "svg")
        .attr("width", this.width)
        .attr("height", this.height);

      this.svg = this.root.append("g")
        .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");




      if(this.xAxis){ //Create graphic xaxis if it need to be displayed
        this.axisXGraphic = this.svg.append("g")
          .attr("transform", "translate(0, " + (this.xAxisOnBottom ? this.domainHeight.toString() : "0") + ")")
          .call(this.axisXCall());
      }

      //Set min and max value on chart
      this.svg.append("g")
        .call(d3.axisLeft(this.axisY).tickValues([this.minDomain, this.maxDomain]));

      //Chart name
      this.svg.append("text")
        .attr("class", "side-title")
        .attr("fill", "#000")
        .attr("transform", "rotate(-90)")
        .attr("x", -this.domainHeight/2)
        .attr("y", -this.margin.left+5)
        .attr("dy", "0.71em")
        .style("text-anchor", "middle")
        .text(this.name);

      //Clip path will contain the line
      this.clip = this.svg.append("defs").append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", this.domainWidth)
        .attr("height", this.height)
        .attr("x", 0)
        .attr("y", 0);

      this.lineArea = this.svg.append("g")
        .attr("clip-path", "url(#clip)");

      //Call create line
      this.createLines();

      //Vertical line following the cursor
      this.verticalLine = this.svg.append("line")
        .attr("class", "verticalLine")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", 0)
        .attr("y2", this.height)
        .style("opacity", 0);


      //Selection rect that will be used to zoom.
      this.selectionRect = this.svg.append("rect")
        .attr("class", "selectionRect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 0)
        .attr("height", this.height)
        .style("opacity", 0);


      //By default the element to listen is this component root. But if multiple chart need to have the same dom element as a listener it can be passed
      var elementListener = this.elementListener ? this.elementListener : this.$refs.root;
      //All the events call
      //id is used to uniquely target this chart
      d3.select(elementListener).on("mousemove."+this.id, function(event){
        self.onMouseMove(event);
      }).on("touchmove."+this.id, function(event){
        self.onMouseMove(event);
      }).on("mouseout."+this.id, function(event){
        self.onMouseOut(event);
      }).on("touchleave."+this.id, function(event){
        self.onMouseOut(event);
      }).on("mousedown."+this.id, function(event){
        self.onMouseDown(event);
      }).on("mouseup."+this.id, function(event){
        self.onMouseUp(event);
      });
    },

    //Default create lines function
    createLines(){
      this.lines = [];
      //Put each data inside line data
      this.data.forEach(lineData => {
        this.lines.push(this.lineArea.append("path")
          .datum(lineData)
          .attr("class", "line line"+this.lines.length)
          .attr("d", this.lineFunction));
      });
    },

    //Return mouse pos inside domain from event object
    getMousePos(event){
      var mouse_x = d3.pointer(event)[0] - this.margin.left;
      var mouse_y = d3.pointer(event)[1] - this.margin.top;
      mouse_x = Math.max(Math.min(mouse_x, this.domainWidth), 0);
      mouse_y = Math.max(Math.min(mouse_y, this.domainHeight), 0);
      return [mouse_x, mouse_y];
    },

    onMouseMove(event){
      var [mouse_x, mouse_y] = this.getMousePos(event);

      //var selectedTime = this.axisX.invert(mouse_x);

      //Update vertical line pos
      this.verticalLine.attr("x1", mouse_x);
      this.verticalLine.attr("x2", mouse_x);

      this.verticalLine.style("opacity", 1);

      //this.textValue.text(numberFormat(this.data[Math.round(selectedTime.getTime()/1000)].data));

      //If mouse button is down, update selection rect
      if(this.isMouseDown){
        this.selectionRect.attr("x", Math.min(mouse_x, this.zoomStart))
          .attr("width", Math.abs(mouse_x-this.zoomStart));
      }
      //call child and listener if other actions are necessary
      this.mouseMoveCall(event, mouse_x, mouse_y);
      this.mouseMoveListener({mouse_x: mouse_x, mouse_y: mouse_y});
    },
    mouseMoveCall(event, mouse_x, mouse_y){},

    onMouseOut(event){
      var [mouse_x, mouse_y] = this.getMousePos(event);
      //If mouse exit hide vertical line
      if(mouse_x <= 0 || mouse_x >= this.width){
        this.verticalLine.style("opacity", 0);
        this.mouseOutCall(event, mouse_x, mouse_y);
      }
    },
    mouseOutCall(event, mouse_x, mouse_y){},

    onMouseDown(event){
      var [mouse_x, mouse_y] = this.getMousePos(event);

      this.isMouseDown = true;
      this.selectionRect.style("opacity", 0.5); //show selection rect
      this.zoomStart = mouse_x; //store first selection position
      this.selectionRect.attr("x", this.zoomStart) //update selection rect position
        .attr("width", 0);
      this.mouseDownCall(event, mouse_x, mouse_y);
    },
    mouseDownCall(event, mouse_x, mouse_y){},

    onMouseUp(event){
      var [mouse_x, mouse_y] = this.getMousePos(event);

      this.isMouseDown = false;
      this.selectionRect.style("opacity", 0); //hide selection rect
      //Calc selection positions
      var extent = [Math.min(this.zoomStart, mouse_x), Math.max(this.zoomStart, mouse_x)];
      //update domains
      if(Math.abs(extent[1]-extent[0])>2){
        this.axisX.domain([this.axisX.invert(extent[0]), this.axisX.invert(extent[1])]);
        this.zoomed = true;
      }else{ //If selection is not large enough (single click for example) unZoom
        this.axisX.domain(this.getMaxDomain());
        this.zoomed = false;
      }

      //update lines
      this.lineArea.selectAll('.line')
        .transition()
        .duration(100)
        .attr("d", this.lineFunction);

      //If axis is display update it
      if(this.xAxis)
        this.axisXGraphic.transition().duration(1000).call(this.axisXCall());

      this.mouseUpCall(event, mouse_x, mouse_y);
      this.zoomListener();
    },
    mouseUpCall(event, mouse_x, mouse_y){},

    axisXCall(){
      //Ticks to display
      if(this.xAxisOnBottom)
        return d3.axisBottom(this.axisX).tickFormat(val => this.axisXFormat(val));
      return d3.axisTop(this.axisX).tickFormat(val => this.axisXFormat(val));
    },

    axisXFormat(date){
      //Format of axis values displayed
      return d3.utcFormat("%d/%m/%Y")(date);
    },


    getCurrentDomain(){ return this.axisX.domain(); },
    getCurrentDomainSize(){ return this.getCurrentDomain()[1] - this.getCurrentDomain()[0]; },

    getMaxDomain(){ return [this.minTime, this.maxTime]; },

    isZoomed(){ return this.zoomed; },
  },

  mounted(){
    this.build();

    //Resize observer on root to update chart
    this.resizeObserver = new ResizeObserver(entries => {
      //On each resize, update size and recreate the chart
      this.setSize();
      this.recreateChart();
    });
    this.resizeObserver.observe(this.$refs.root);
  },
  beforeDestroy(){
    //On destruction remove the svg and the resize observer
    this.root.remove();
    this.resizeObserver.disconnect();
  },
};
</script>

<style lang="scss">
.linechart{
  width: 100%;
  display: inline-block;
}

.verticalLine{
  stroke: grey;
  stroke-width: 1px;
}
.selectionRect{
  fill: grey;
  stroke: black;
  stroke-width: 1px;
}

.line {
  fill: none;
  stroke-width: 1px;
  stroke: black;
}
</style>
