/*
 * Parse a text describing a workout and convert it to a workout
 */
const workoutUtil = require('./workoutUtil.js');
const ohm = require('ohm-js');


//Define the possible grammar
//A workout is a list of block that are either step or step repeat
//Step: duration@targets options
//Repeat: n*(steps)
//Targets is a power/hr or RPE target with an optional cadence target
//Options are mostly lap button steps options (see workout exports to garmin steps for more details)
const workoutGrammar = ohm.grammar(`
  Workout{
    workout = blocks
    blocks = block+
    block = ","* number "*(" blocks ")" ","* --repeatition
          | ","* step ","* --step
    step = duration "@" targets options
    duration = digit digit* ":" digit digit ":" digit digit --hours
          | digit digit* ":" digit digit --minutes
          | digit digit* --seconds
    targets = target cadenceTarget*
    target = percentage powerType --power
          | percentage heartRateType --heartRate
          | rpeNumber "rpe" --rpe
    cadenceTarget = number "rpm"
    percentage = digit digit* digit* "%"
    powerType = "ftp" | "max" | "wbal" | "map"
    heartRateType = "hrt" | "hrmax"
    options = lapOption* onTargetOption*
    lapOption = "lap" | "!lap"
    onTargetOption = "ontarget" | "!ontarget"
    rpeNumber = "10" | "1".."9"
    number = digit+
  }
`);

//text to used unit in database (ftp -> threshold for example)
const powerTypeEval = { 'ftp': 'threshold', 'max': 'max', 'wbal': 'anaerobic', 'map': 'map' };
const heartRateTypeEval = { 'hrt': 'threshold', 'hrmax': 'max' };


//Check if step target is above zone 2
function isTargetAboveZone2(step){
  if(step.target_unit === 'power'){
    if((step.target_type === 'threshold' && step.target > 76) || step.target_type === 'max' || step.target_type === 'anaerobic' || step.target_type === 'map')
      return true;
  }else if(step.target_unit === 'heart_rate'){
    if((step.target_type === 'threshold' && step.target > 84) || (step.target_type === 'max' && step.target > 60))
      return true;
  }else if(step.target_unit === 'rpe' && step.target > 3){
    return true;
  }
  return false;
}

//Check if step should end on lap button
//Should end on lap button if it's in zone 2 and longer than 3 minutes and there is a higher than zone 2 interval following up
//Unless an option saying otherwise has been specified
function endLapButton(step, nextStep){
  if(typeof step.end_lap_button === "boolean") //If specific lap option already defined, return it
    return step.end_lap_button;

  if(!nextStep)
    return false;

  if(step.duration < 180) //Duration shorter than 3min, no lap button
    return false;

  //lap button if current target isnt above zone 2 but next target is above
  return !isTargetAboveZone2(step) && isTargetAboveZone2(nextStep);
}

//Get step following this step (if it exist)
function getNextStep(steps, i, isRepeat){
  if(i+1 >= steps.length){ //If end of steps list
    if(isRepeat) //If it's inside a step repeat, look at the first step of the list
      return getNextStep(steps, -1, isRepeat);
    return null;
  }

  let step = steps[i+1];

  if(step.type === 'REPEAT') //if next step is a repeat
    return getNextStep(step.steps, -1, true); //look at the first step inside the repeat
  return step;
}

//Set lap button option on each step where it's required
//By default on any step in zone 2 or below longer than 3minutes and before an intensity step (above zone 3)
function setLapsButtonForSteps(steps, isRepeat = false){
  for(let i=0; i<steps.length; i++){
    let step = steps[i];
    if(step.type === 'REPEAT'){
      setLapsButtonForSteps(step.steps, true);
    }else{
      let nextStep = getNextStep(steps, i, isRepeat);
      step.end_lap_button = endLapButton(step, nextStep);
    }
  }
}



//Workout semantics will convert the parsed grammar into workout steps
const workoutSemantics = workoutGrammar.createSemantics().addOperation('eval', {
  workout(blocks){
    return blocks.eval();
  },

  blocks(blocks){ //Blocks are converted to a list of steps
    var steps = [];
    var bls = blocks.eval();
    for(var block of bls)
      steps = steps.concat(block); //concat because evaluated step is in an array
    return steps;
  },

  block_repeatition(_1, number, _2, blocks, _3, _4){ //A repeat is converted to a step repeat
    var steps = blocks.eval(); //Sub steps list
    var repeatitions = number.eval();
    var duration = 0;
    for(var step of steps)
      duration += step.duration;
    duration *= repeatitions;
    return { type: 'REPEAT', name: '', steps: steps, repeatitions: repeatitions, duration: duration };
  },
  block_step(_1, step, _2){ //return step inside an array in case it's alone
    return [ step.eval() ];
  },

  step(duration, _, targets, options){ //Step, eval targets, duration and options
    var tar = targets.eval();
    var dur = duration.eval();
    var opt = options.eval();
    return { type: 'STEP', name: '', duration: dur, target: tar.target, target_unit: tar.target_unit, target_type: tar.target_type,
     cadence_target: tar.cadence || 0, end_lap_button: opt.lap, start_lap_on_target_power: opt.onTarget };
  },

  targets(target, cadenceTarget){ //return targets
    var tar = target.eval();
    var cadence = cadenceTarget.eval();
    if(cadence.length > 0 && cadence[0])
      tar.cadence = cadence[0];
    return tar;
  },

  target_power(percentage, powerType){
    return { target: percentage.eval(), target_type: powerType.eval(), target_unit: 'power' };
  },
  target_heartRate(percentage, heartRateType){
    return { target: percentage.eval(), target_type: heartRateType.eval(), target_unit: 'heart_rate' };
  },
  target_rpe(rpeNumber, rpe){
    return { target: rpeNumber.eval(), target_type: undefined, target_unit: 'rpe' };
  },

  cadenceTarget(number, rpm){
    return number.eval();
  },

  powerType(_){ return powerTypeEval[this.sourceString]; },
  heartRateType(_){ return heartRateTypeEval[this.sourceString]; },

  percentage(_1, _2, _3, _4){
    return parseInt(this.sourceString.split('%')[0]);
  },

  duration_seconds(_1, _2){ return parseInt(this.sourceString); },
  duration_minutes(_1, _2, _3, _4, _5){
    var split = this.sourceString.split(':');
    return parseInt(split[0])*60 + parseInt(split[1]);
  },
  duration_hours(_1, _2, _3, _4, _5, _6, _7, _8){
    var split = this.sourceString.split(':');
    return parseInt(split[0])*3600 + parseInt(split[1])*60 + parseInt(split[2]);
  },

  options(lapOption, onTargetOption){
    lapOption = lapOption.eval();
    onTargetOption = onTargetOption.eval();
    let lap = lapOption.length > 0 ? lapOption[0] : undefined;
    let onTarget = onTargetOption.length > 0 ? onTargetOption[0] : false;
    return { lap: lap, onTarget: onTarget };
  },
  lapOption(_){
    if(this.sourceString === 'lap')
      return true;
    if(this.sourceString === '!lap')
      return false;
    return undefined;
  },
  onTargetOption(_){
    return this.sourceString === 'ontarget';
  },

  number(_){ return parseInt(this.sourceString); },
  rpeNumber(_){ return parseInt(this.sourceString); },
});


//Parse and convert steps from text with the grammar and ohm parser
function parseSteps(text){
  text = text.replace(/\s/g, '').toLowerCase(); //remove any space/tab/line return and put everything to lowercase
  var match = workoutGrammar.match(text);
  if(match.failed()) //Check if the text match the grammar first
    throw match.message; //throw error if not

  var steps = workoutSemantics(match).eval(); //Parse and convert

  setLapsButtonForSteps(steps); //Set laps buttons options defaults

  if(steps[0].type === 'STEP') //first step (warmup) is also a lap button step
    steps[0].end_lap_button = true;

  return steps;
}

//Check if text match grammar
function textMatch(text){
  text = text.replace(/\s/g, '').toLowerCase();
  var match = workoutGrammar.match(text);
  return match.succeeded();
}

//Error message if text don't match grammar
function textMatchErrorMessage(text){
  text = text.replace(/\s/g, '').toLowerCase();
  var match = workoutGrammar.match(text);
  if(match.failed())
    return match.message;
  return '';
}


module.exports = {
  parseSteps,
  textMatch,
  textMatchErrorMessage,
};
